<template>
    <section class="outContainer">
      <!-- 搜索栏 -->
      <div class="search">
        <el-input class="each"  placeholder="设备编号" v-model="searchInfo.param.deviceId"></el-input>
        <el-select class='each' placeholder="在线状态" v-model="searchInfo.param.status" clearable>
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select class='each' placeholder="运营状态" v-model="searchInfo.param.operateState" clearable>
          <el-option
            v-for="item in operateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-cascader class="each" placeholder="所属商户 / 所属小区" v-model="unitId" v-if="userInfo.roleLevel < 5"
          clearable
          :options="managerUnitOptions"
          @change="chooseMerchant"
          :props="props"
        ></el-cascader>
        <el-select class='each' placeholder="安装小区" v-model="searchInfo.param.unitId" clearable  v-if="userInfo.roleLevel >= 5 && userInfo.roleLevel < 10">
          <el-option
            v-for="item in unitOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
        <el-button size="mini" @click="openDrawer(null)">新增</el-button>
      </div>
      <!-- 数据表格 -->
      <div class="tableContainer">
        <el-table
         :data="tableList"
         height="100%">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table
             border
             :data="scope.row.chargeDischargePorts"
             :header-cell-style="{ background:'#ddd' }"
            >
              <el-table-column align="center" label="端口号" prop="portNum">
              </el-table-column>
              <el-table-column align="center" label="类型" prop="type">
                <template slot-scope="props">
                  {{ props.row.type == 1 ? "充电" : (props.row.type == 2 ? "充放电一体" : "--")  }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="端口状态" prop="portStatusName">
              </el-table-column>
              <el-table-column align="center" label="仓门状态" prop="doorStatusName">
              </el-table-column>
              <el-table-column align="center" label="仓内温度" prop="temperature">
                <template slot-scope="props">
                  {{ props.row.temperature || props.row.temperature == 0 ? props.row.temperature + "℃" : "--" }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="版本号" prop="version">
                <template slot-scope="props">
                  {{ props.row.version ? props.row.version : "--" }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" fixed="right" width="250px">
                <template slot-scope="props">
                  <el-button size="mini" @click="handleUpgrades(props.row)">升级</el-button>
                  <el-button size="mini" @click="handleOpenDoor(props.row,'port')">打开柜门</el-button>
                  <el-popover placement="top" trigger="click" width="auto">
                      <div class="moreBtn">
                        <span class="spanBtn" @click="handleAddPort(props.row)" v-if="userInfo.roleLevel <= 5">编辑</span>
                        <span class="spanBtn" @click="handleDel(props.row,'port')" v-if="userInfo.roleLevel <= 5">删除</span>
                        <span class="spanBtn" @click="restart(props.row,'port')">重启</span>
                      </div>
                      <el-button style="margin-left: 10px;" slot="reference" size="mini">更多</el-button>
                  </el-popover>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
         <el-table-column align="center" label="设备编号" prop="id" width="200px">
          <template slot-scope="scope">
            <p style="display:flex;align-items:center;color:#40a9ff;justify-content: center;">
              {{ scope.row.id }}
              <img style="cursor: pointer; margin-left: 3px; height:15px;width:15px" src="@/static/img/qrCode.png" alt="点击查看设备二维码" @click="openQrCode(scope.row)">
            </p>
          </template>
         </el-table-column>
         <el-table-column align="center" label="费用信息" prop="fee" min-width="150px">
          <template slot-scope="scope">
            <p>充电：{{ scope.row.chargeFee ? scope.row.chargeFee + ' 元 / 次' : '--'}}</p>
            <p>充放电：{{ scope.row.chargeDischargeFee ? scope.row.chargeDischargeFee + ' 元 / 次' : '--'}}</p>
          </template>
         </el-table-column>
         <el-table-column align="center" label="在线状态" prop="statusName">
         </el-table-column>
         <el-table-column align="center" label="运营状态" prop="operateStateName">
         </el-table-column>
         <el-table-column align="center" label="版本" prop="version">
          <template slot-scope="scope">
            <p>{{ scope.row.version ? scope.row.version : '--' }}</p>
          </template>
         </el-table-column>
         <el-table-column align="center" label="所属小区" prop="unitName">
         </el-table-column>
         <el-table-column align="center" label="备注" prop="remark">
          <template slot-scope="scope">
            <p>{{ scope.row.remark ? scope.row.remark : '--' }}</p>
          </template>
         </el-table-column>
         <el-table-column align="center" label="创建时间" prop="createTimeStr">
         </el-table-column>
         <el-table-column align="center" label="操作" fixed="right"  width="300px">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleMaintenance(scope.row)" v-if="scope.row.operateState != 3"> {{scope.row.operateState == 2 ? '退出维护' : '维护' }}</el-button>
            <el-button size="mini" @click="handleAddPort(scope.row)">新增端口</el-button>
            <el-popover placement="top" trigger="click" width="auto">
                <div class="moreBtn">
                  <span class="spanBtn" @click="openDrawer(scope.row)" v-if="userInfo.roleLevel <= 5">编辑</span>
                  <span class="spanBtn" @click="handleDel(scope.row,'device')" v-if="userInfo.roleLevel <= 5">删除</span>
                  <span class="spanBtn" @click="restart(scope.row,null)">重启</span>
                </div>
                <el-button style="margin-left: 10px;" slot="reference" size="mini">更多</el-button>
            </el-popover>
          </template>
         </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
      <!-- 新增、编辑 -->
      <el-drawer :title="row ? '编辑' : '新增'" :visible.sync="addDrawer" size="50%">
        <add v-if="addDrawer" @close="handleClose" ref='newForm' :row="row"></add>
      </el-drawer>
      <!-- 新增、编辑端口 -->
      <el-drawer :title="row ? '编辑' : '新增'" :visible.sync="addPortDrawer" size="50%">
        <add-port v-if="addPortDrawer" @close="handleClose" ref='newForm' :row="row"></add-port>
      </el-drawer>
      <!-- 设备二维码 -->
      <el-dialog :title="'设备编号：'+qrCodeInfo.id" :visible.sync="qrCodeVisible" width="330px">
        <vue-qr :text="qrCode" :size="200"></vue-qr>
      </el-dialog>
    </section>
  </template>
  
  <script>
  import Pagination from '@/components/Pagination.vue';
  import add from './add.vue';
  import { timeChange } from '@/api/publicFun.js';
  import { getMerchantList,getNeighbourhoodList } from '@/api/public.js';
  import { getChargeDischargeList,maintainChargeDischarge,deleteChargeDischarge,deletePort,restartDeviceOrPort,openDoor,getPortInfo } from "@/api/chargeDischargeApi.js";
  import vueQr from 'vue-qr';
  import AddPort from './addPort.vue';
  import qrCodeObj from '@/constants/miniProgramQrCodeUrl.js';
  export default {
    data() {
      return {
        tableList:[], // 表格数据
        searchInfo:{ // 筛选条件
          pageNo:1,
          pageSize:10,
          param:{
            deviceId:"",
            merchantId:"",
            unitId:"",
            name:"",
            status:"",
          },
          total:0,
        },
        statusOptions:[ // 在线状态选项
          {value:1,label:'在线'},
          {value:2,label:'离线'},
        ],
        operateOptions:[ // 运营状态选项
          {value:1,label:'运营中'},
          {value:2,label:'停止运营'},
          {value:3,label:'故障'},
        ],
        addDrawer:false, // 控制新增弹框是否显示
        addPortDrawer:false,
        row:null, // 当前操作表格列表数据
        userInfo:JSON.parse(localStorage.getItem('userInfo')), // 当前登录账号用户信息
        managerUnitOptions:[], // 商户选项
        unitId:[], // 商户、小区联动弹框绑定属性
        props: { // 所属商户、小区 联动选择框配置
          value:'id',
          label:'name',
          lazy: true,
          checkStrictly:true,
          lazyLoad (node, resolve) {
            const { level } = node;
            setTimeout(() => {
              let data = {
                pageNo:1,
                pageSize:1000,
                param:{
                  name:null,
                  merchantId:node.value,
                }
              }
              if(level == 1){
                getNeighbourhoodList(data).then(res=>{
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  let nodes = [];
                  res.data.list.forEach(element=>{
                      nodes.push({
                        id: element.id,
                        name: element.name,
                        leaf: level >= 1,
                        children:null,
                      })
                  })
                  // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  resolve(nodes);
                })
                console.log(node,908)
              }
            }, 1000);
          }
        },
        qrCodeVisible:false, // 控制查看设备二维码弹框是否显示
        qrCodeInfo:{}, // 查看二维码弹框信息
        qrCode:null, // 当前查看设备二维码完整路径
        dialogFormVisible:false, // 强制停止端口弹框是否显示
        stopForm:{}, // 停止端口表单数据
        stopPortRules:{ // 停止端口表单验证
          port:[
            {required:true,message:'请选择需要停止的端口',trigger:'blur'}
          ],
        },
      }
    },
  
    components: {
      Pagination,
      add,
      vueQr,
      AddPort
    },
  
    computed: {},
  
    mounted() {
      this.init();
      this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : '';
    },
  
    methods: {
      /**
       * 条件筛选部分按钮功能
       * @param {*String} type 按钮类型 clear清空搜索条件 search搜索
       */
      handleSearch(type){
        this.searchInfo.pageNo = 1;
        if(type == 'clear'){
          this.searchInfo.param = {
            id:"",
            merchantId:"",
            name:"",
            status:"",
          }
          this.unitId = [];
        }
        this.init();
      },
      // 查看二维码
      openQrCode(row){
        this.qrCodeInfo = {...row};
        this.qrCode = `${qrCodeObj.chargeDischarge}${row.id}`; // 正式服
        // this.qrCode = `${qrCodeObj.testChargeDischarge}${row.id}`; // 测试服
        this.qrCodeVisible = true;
      },
      // 获取小区列表
      getNieghbourList(){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:null,
          }
        }
        getNeighbourhoodList(data).then(res=>{
          if(res.success){
            this.unitOptions = res.data.list;
          }
        })
      },
      // 获取商户列表
      async getMerchantOptions(){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:'',
          }
        }
        await getMerchantList(data).then(res=>{
          if(res.success){
            this.managerUnitOptions = res.data.list;
            this.managerUnitOptions.forEach(element=>{
              element.children = [];
            })
          }else{
            this.managerUnitOptions = [];
          }
        })
      },
      // 选择商户、小区
      chooseMerchant(e){
        this.searchInfo.param.merchantId = e[0] ? e[0] : null;
        this.searchInfo.param.unitId = e[1] ? e[1] : null;
      },
      // 获取表格列表数据
      async init(){
        await getChargeDischargeList(this.searchInfo).then(res => {
          if(res.success){
            this.tableList = res.data.list;
            this.searchInfo.total = res.data.total;
            this.tableList.forEach(element => {
              element.createTimeStr = element.createTime ? timeChange(element.createTime,'seconds') : '--';
            });
          }else{
            this.tableList = [];
            this.searchInfo.total = 0;
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
        })
      },
      // 获取端口信息
      getPortDetail(portId){
        getPortInfo({param:portId}).then(res=>{
          if(res.success){
            return res.data;
          }else {
            return false;
          }
        })
      },
      // 打开仓门
      handleOpenDoor(row){
        row = this.getPortDetail(row.id) ? this.getPortDetail(row.id) : row;
        let remind = row.orderId ? `该端口存在未完成订单，确认打开吗？开柜成功后，该订单自动关闭，用户将无法使用小程序进行开柜` : `确认打开该柜门吗？`;
        this.$confirm(remind).then(async ()=>{
          let data = {
            param:{
              deviceId:row.chargeDischargeId,
              portNum:row.portNum,
              orderId:row.orderId ? row.orderId : null,
            }
          }
          await openDoor(data).then(res=>{
            this.$message({
              message:res.success ? "指令下发成功" : res.msg,
              type:res.success ? "success" : "fail"
            })
            this.init();
          })
        })
      },
      // 进入、退出维护
      handleMaintenance(row){
        this.$confirm('确认执行该操作吗？').then(()=>{
          let data = {
            param:{
              deviceId:row.id,
              operateState:row.operateState == 1 ? 2 : 1,
            }
          }
          maintainChargeDischarge(data).then(res=>{
            this.$message({
              message:res.success ? '操作成功' : res.msg,
              type:res.success ? 'success' : "fail",
            })
          })
          this.init();
        })
      },
      // 重启
      restart(row,type){
        let remind = row.orderId && type == 'port' ? `该端口存在未完成订单，确认重启吗？` : `确认执行该操作吗？`;
        this.$confirm(remind).then(async ()=>{
          let data = {
            param:{
              deviceId: type == 'port' ? row.chargeDischargeId : row.id,
              port: type == 'port' ? row.portNum : null,
            }
          }
          await restartDeviceOrPort(data).then(res=>{
            this.$message({
              message:res.success ? "指令下发成功" : res.msg,
              type:res.success ? "success" : "fail"
            })
            this.init();
          })
        })
      },
      // 删除
      handleDel(row,type){
        this.$confirm('确认执行该操作吗？').then(async ()=>{
          if(type == "device"){
            await deleteChargeDischarge({param:row.id}).then(res=>{
              this.$message({
                message:res.success ? '操作成功' : res.msg,
                type:res.success ? 'success' : 'fail'
              })
              this.init();
            })
          }else{
            await deletePort({param:row.id}).then(res=>{
              this.$message({
                message:res.success ? '操作成功' : res.msg,
                type:res.success ? 'success' : 'fail'
              })
              this.init();
            })
          }
        })
      },
      // 打开侧边弹框
      openDrawer(row){
        this.row = row ? {...row} : row;
        this.addDrawer = true;
      },
      handleAddPort(row){
        this.row = row ? {...row} : row;
        this.addPortDrawer = true;
      },
      // 关闭侧边弹窗
      handleClose(){
        this.addDrawer = false;
        this.addPortDrawer = false;
        this.init();
      },
      // 分页操作事件
      callFather(parm){
        this.searchInfo.pageNo = parm.currentPage;
        this.init();
      },
    },
  };
  </script>
  <style scoped lang="scss" scoped>
    .moreBtn{
      text-align: right;
      margin: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .spanBtn{
      width: 100%;
      cursor: pointer;
      display: inline-block;
      color: #40a9ff;
      text-align: center;
      padding-bottom: 5px;
    }
    .eachPort{
      display: inline-block;
      height: 30px;
      width: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 5px;
      border: solid 1px #ccc;
      cursor: pointer;
    }
    .el-tooltip{
      display: inline-block;
      padding: 0 6px;
      margin-right: 5px;
      margin-bottom: 5px;
      border-radius: 3px;
      margin-left: 0 !important;
      width: 30px;
    }
    .portStatus{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .free{ // 空闲
      color: #88d75f;
      border: #88d75f solid 1px  !important;
      background: #f6ffed;
    }
    .charging{ // 充电中
      color: #3aa1ff;
      border: #3aa1ff solid 1px !important;
      background: #e6f7ff;
    }
    .discharging{ // 放电中
      color: #5062b4;
      border: #5062b4 solid 1px !important;
      background: #d0d7f7;
    }
    .fault{ // 故障
      color: #ff696b;
      border: #ff696b solid 1px !important;
      background: #fff2f0;
    }
    .take{ // 已插枪
      color: #e2cd57;
      border: #e2cd57 solid 1px  !important;
      background: #ffffe5;
    }
  </style>
  