<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="170px" style="width:95%;overflow:auto">
        <el-form-item label="充放电柜编号" prop="chargeDischargeId">
          <el-input placeholder="请输入设备编号" v-model="form.chargeDischargeId" disabled></el-input>
        </el-form-item>
        <el-form-item label="端口号" prop="portNum">
          <el-input placeholder="请输入端口号" v-model="form.portNum" type="number" @input="handleInput('portNum')" :disabled="row.chargeDischargeId ? true : false"></el-input>
        </el-form-item>
        <el-form-item label="端口类型" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio-button :label="1">充电</el-radio-button>
            <el-radio-button :label="2">充放电一体</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="handleSubmit('form')" :plain='true'>立即提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  import { addPort,editPort } from "@/api/chargeDischargeApi.js";
  export default {
    props:['row'], // 父组件携带参数
    data() {
      return {
        form:{
          chargeDischargeId:null,
        },
        rules: { // 表单验证
          portNum:[
            { required: true, message: '请输入端口号', trigger: 'blur',}
          ],
          type:[
            { required: true, message: '请选择端口类型', trigger: 'blur',}
          ],
        },
        userInfo:JSON.parse(localStorage.getItem('userInfo')), // 当前登陆账号用户信息
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      console.log(this.row,999)
      if(this.row.chargeDischargeId){
        this.form = {...this.row};
      }else{
        this.form.chargeDischargeId = this.row.id;
      }
    },
  
    methods: {
      /**
       * 数字输入框将String类型的数字转换为Number类型的数字
       * @param {*String} value 输入框双向绑定的表单对象对应的属性
       */
      handleInput(value){
        this.form[value] = parseFloat(this.form[value]);
      },
      // 关闭弹窗
      close(form){
         this.$emit('close');
      },
      // 新增
      async add(){
        await addPort({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
      },
      // 编辑
      async edit(){
        await editPort({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
      },
      // 提交
      handleSubmit(form) {
         this.$refs["form"].validate(valid => {
            if (valid) {
                this.$confirm('确认提交吗？').then(()=>{
                  if(this.row.chargeDischargeId){
                    this.edit();
                  }else{
                    this.add();
                  }
                })
            }else{
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss" scoped>
  // 防止地图自动完成的对象被遮挡
  .tangram-suggestion {
      z-index: 9999 !important;
    }
   .el-drawer{
     overflow:auto !important;
   }
   /deep/ .el-form-item__content{
    display: flex;
   }
   .map{
    border: 1px solid #dcdfe6;
    background: #fff;
    border-radius: 4px;
    width: 80px;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  