import axios, { ResponseData } from './base.ts';
import { AxiosPromise } from 'axios';

// 新增充放电柜
export function addChargeDischarge(data = {}){
    return axios.request({
      url:`/admin/chargeDischarge/create`,
      method:'post',
      data:data,
    })
}

// 编辑充放电柜
export function updateChargeDischarge(data = {}){
    return axios.request({
      url:`/admin/chargeDischarge/update`,
      method:'post',
      data:data,
    })
}

// 删除充放电柜
export function deleteChargeDischarge(data = {}){
    return axios.request({
      url:`/admin/chargeDischarge/delete`,
      method:'post',
      data:data,
    })
}

// 查询充放电柜列表
export function getChargeDischargeList(data = {}){
    return axios.request({
      url:`/admin/chargeDischarge/pageList`,
      method:'post',
      data:data,
    })
}

// 维护充放电柜
export function maintainChargeDischarge(data = {}){
    return axios.request({
      url:`/admin/chargeDischarge/maintain`,
      method:'post',
      data:data,
    })
}

// 设备、端口重启
export function restartDeviceOrPort(data = {}){
    return axios.request({
      url:`/admin/chargeDischarge/down/restart`,
      method:'post',
      data:data,
    })
}

// 打开柜门
export function openDoor(data = {}){
    return axios.request({
      url:`/admin/chargeDischarge/port/openDoor`,
      method:'post',
      data:data,
    })
}

// 新增端口
export function addPort(data = {}){
    return axios.request({
      url:`/admin/chargeDischarge/port/create`,
      method:'post',
      data:data,
    })
}

// 编辑端口
export function editPort(data = {}){
    return axios.request({
      url:`/admin/chargeDischarge/port/update`,
      method:'post',
      data:data,
    })
}

// 获取端口信息
export function getPortInfo(data = {}){
  return axios.request({
    url:`/admin/chargeDischarge/port/getInfo`,
    method:'post',
    data:data,
  })
}

// 删除端口
export function deletePort(data = {}){
    return axios.request({
      url:`/admin/chargeDischarge/port/delete`,
      method:'post',
      data:data,
    })
}

// 订单部分

// 查询订单列表
export function getChargeDischargeOrderList(data = {}){
  return axios.request({
    url:`/admin/chargeDischarge/order/page`,
    method:'post',
    data:data,
  })
}

// 查询订单详情
export function getChargeDischargeOrderDetail(data = {}){
  return axios.request({
    url:`/admin/chargeDischarge/order/get`,
    method:'post',
    data:data,
  })
}

// 导出订单列表
export function exportChargeDischargeOrder(data = {}){
  return axios.request({
    url:`/admin/chargeDischarge/order/listExportOrder`,
    method:'post',
    data:data,
  })
}